.footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  
  .links-logo {
    flex: 1;
    background-color: rgba(49, 85, 164, 0.05);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 35px;
    padding-left: 45px;

    .links {
      color: #3f68c2;

      a:hover {
        text-decoration: underline;
      }
    }
  }

  .footer-carousel {
    background-color: white;
    width: 474px;
    height: 170px;

    .carousel-wrapper {
      display: flex;
      flex-direction: column;
      padding: 14px 30px;

      .carousel-root {
        max-height: 120px;
        .carousel-img {
          max-height: 100px;
          max-width: fit-content;
        }
        .carousel-empty-block {
          height: 100px;
          display: block;
          width: 100px;
        }
        .bloc-carousel {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
        }
      }

      .indicators {
        display: flex;
        gap: 17px;
        width: 100%;
        margin-top: 16px;
        margin-left: 11px;
        .play-pause {
          width: 22.07px;
          height: 25.91px;
          cursor: pointer;
        }
        .indexes {
          display: flex;
          align-items: center;
          flex: 1;
          gap: 15px;

          .indicator {
            background-color: #5d91fe;
            border-radius: 50%;
            cursor: pointer;

            &.off {
              width: 8px;
              height: 8px;
            }
            &.on {
              width: 13px;
              height: 13px;
            }
          }
        }
      }
    }
  }

  &.mobile {
    flex-direction: column;

    .links-logo {
      flex-direction: column;
      padding-bottom: 30px;
      padding-top: 30px;
    }

    .footer-carousel {
      width: 100%;

      .carousel-wrapper {
        .carousel-root {
          .carousel-img {
            width: 100px;
          }
        }
      }
    }
  }
}
