@import "../../../styles/variables";

.update-infos {
  background-color: $vnfbleuClair;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}
