@import "../styles.scss";

.select-btn {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  border: none;
  border: 1px solid rgba(49, 85, 164, 0.16);
  padding-left: 13px;
  padding-right: 13px;
  border-radius: 4px;
  font-family: "Roboto";
  font-size: 15px;
  font-weight: 300;

  &.mobile {
    font-weight: 300;
    color: black;
    font-size: 15px;
  }
}

.search-box {
  position: absolute;
  padding: 0;
  background-color: #ffffff;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  top: 56px;
  z-index: 999;

  li {
    padding: 10px;
    &:hover {
      background-color: #f5f5f5;
    }
    span {
      color: black;
      font-weight: 300;
      font-size: 14px;
    }
  }
}
