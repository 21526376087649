@import "../../../styles/variables";

.update-infos .header {
  height: 60px;
  width: 100%;
  background-color: $vnfbleuA2;
  display: flex;
  justify-content: center;
  align-items: center;

  .title {
    color: white;
    h1 {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 700;
      font-size: 19px;
      line-height: 22px;
      text-align: right;
      letter-spacing: 0.7px;

      .connect {
        font-weight: 300;
      }
    }
    .separator {
      max-width: 128px;
    }
  }
}
