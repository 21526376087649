@import "../../../styles/variables";

.update-infos .form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 30px;
  align-items: center;

  /**************************
  /* FORM HEADER - USER PART
  ***************************/
  .user {
    display: flex;
    align-items: center;
    color: $vnfbleuA3;
    gap: 17px;

    .user-text {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.711177px;
    }
  }

  /**************************
  /* FORM HEADER - TEXT PART
  ***************************/
  .form-header {
    .title {
      font-weight: 600;
      font-size: 30px;
      color: $vnfbleuA3;
      font-style: normal;
      line-height: 30px;
      text-align: center;
    }
    .subtitle {
      font-weight: 400;
      font-size: 14px;
      color: $vnfbleuA3;
      font-style: normal;
      line-height: 30px;
      text-align: center;
    }
  }

  /**************************
  /* FORM - LINE SEPARATOR
  ***************************/
  .line-separator {
    width: 100%;
    height: 4px;
    background-color: $vnfbleuA2;
  }

  /**************************
  /* FORM - MAIN FORM
  ***************************/
  .main-form {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 600px;

    .inner-form {
      grid-gap: 10px;
      display: grid;
      grid-auto-flow: row dense;
      grid-template-columns: repeat(8, 1fr);
      grid-template-rows: repeat(4, 1fr);

      .gender {
        grid-area: 1 / 1 / 1 / 3;
      }
      .firstName {
        grid-area: 1 / 3 / 1 / 6;
      }
      .lastName {
        grid-area: 1 / 6 / 1 / 9;
      }
      .address {
        grid-area: 2 / 1 / 2 / 9;
      }
      .email {
        grid-area: 3 / 1 / 3 / 5;
      }
      .telephone {
        grid-area: 3 / 5 / 3 / 9;
      }
      .activity {
        grid-area: 4 / 1 / 4 / 5;
      }
    }
  }

  /**************************
  /* FORM - CHECKBOXES & LINKS
  ***************************/
  .rgpd {
    display: flex;
    flex-direction: column;
    gap: 12px;
    .text-rgpd {
      color: #767676;
      a {
        color: $vnfbleuA3;
        text-decoration: underline;
      }
    }
  }
  .rgpd-error {
    color: $vnfbleuA3;
    padding: 12px 8px;
    border-radius: 4px;
    text-align: center;
    margin-top: 12px;
  }
  /**************************
  /* FORM - BUTTONS SUBMIT
  ***************************/
  .buttons {
    display: flex;
    justify-content: space-between;
    .cancel-btn {
      color: $vnfbleuA3;
      border: 1.5px solid $vnfbleuA3;
      background-color: white;
      min-width: 110px;
      cursor: pointer;

      &:hover {
        color: #ffffff;
        background-color: $vnfbleuA3;
      }
    }
    .save-btn {
      background-color: $vnfbleuA3;
      box-shadow: 5px 5px 0px $vnfVertClair;
      color: white;
      min-width: 110px;

      &:hover {
        background-color: #3c64b5;
      }
    }
  }

  &.mobile {
    .main-form {
      width: 100%;
      padding: 0 20px;

      .inner-form {
        display: flex;
        flex-direction: column;
        gap: 12px;
      }
    }
  }

  .required-fields {
    color: grey;
    font-size: 14px;
  }
}
