@import "../styles.scss";
@import "../../../styles/variables";

.input {
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid rgba(49, 85, 164, 0.16);
  color: black;
  font-family: "Roboto";
  font-size: 0.9rem;
  font-size: 14px;
  font-weight: 300;
  height: 50px;
  padding: 0.8rem 0.8rem 1rem;
  width: 100%;

  &:disabled {
    color: grey;
  }
}
