.input-container {
  display: flex;
  flex-flow: column-reverse;
  position: relative;
  label {
    position: relative;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #767676;
    span {
      position: absolute;
      display: block;
      bottom: 0;
      color: #767676;
      margin-bottom: 0 !important;
      padding: 1.5rem 0.8rem;
      cursor: text;
      transition: all 0.3s ease-in-out;
      pointer-events: none;
    }
  }

  span,
  input {
    transition: all 0.3s ease-in-out;
    touch-action: manipulation;
  }

  span {
    color: white;
  }

  input:focus {
    outline: 0;
  }

  ::-webkit-input-placeholder {
    opacity: 0;
    transition: inherit;
  }

  input:focus::-webkit-input-placeholder {
    opacity: 1;
  }

  input:not(:placeholder-shown) + span,
  input:focus + span {
    left: 12px;
    top: -7px;
    height: 18px;
    background-color: #f1f6fe;
    font-size: 12px;
    color: #767676;
    cursor: pointer;
    padding: 0 5px;
  }

  &.errored {
    input,
    label,
    div,
    span {
      color: red !important;
      border-color: red;
    }
    span.error {
      color: red;
      font-size: 12px;
      margin-bottom: 5px;
    }
  }

  &.mobile {
    font-size: 14px;

    span {
      font-size: 14px;
    }
    input {
      font-size: 14px;
    }

    input:focus + span {
      bottom: 90%;
      font-size: 14px;
      color: var(--color-text-blue-light);
      cursor: pointer;
      padding: 0 1rem;
    }
  }
}
