@import "../../../styles//variables.scss";

.home {
  background-image: url("./background.svg");
  background-size: cover;
  width: 100%;
  height: 100vh;

  display: flex;
  justify-content: center;
  .box {
    width: 320px;

    .box-top {
      background-color: $vnfbleuA2;
      text-align: right;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 13px;
      padding-bottom: 30px;
      padding-right: 30px;
      padding-top: 25px;

      .box-top-title {
        text-align: right;
        h1 {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 700;
          font-size: 30px;
          line-height: 35px;
          text-align: right;
          letter-spacing: 0.7px;
          color: white;

          .connect {
            font-weight: 300;
          }
        }
      }

      .box-top-text {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: white;
      }
    }
    .box-bottom {
      background-color: $vnfbleuA3;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 150px;

      button {
        width: 100%;
        border: solid 1px #fff;
        background-color: #ffffff;
        box-shadow: 5px 5px 0px $vnfJaune;
        padding: 8px 0px;
        color: #446bbb;
        letter-spacing: 0.7px;
        gap: 4px;
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        cursor: pointer;
        align-items: center;
        max-width: 127px;

        &:hover {
          background-color: #3c64b5;
          border: solid 1px #3c64b5;
          color: #ffffff;
        }

        &:disabled {
          opacity: 0.6;
          cursor: default;
        }
      }
    }
  }

  &.mobile {
    .box {
      width: 100%;

      .box-top {
        gap: 20px;
        .box-top-title {
          h1 {
            font-size: 34px;
          }
        }
        .box-top-text {
          font-size: 18px;
          > p {
            margin-top: 8px;
          }
        }
      }
      .box-bottom {
        min-height: 120px;
      }
    }
  }
}
